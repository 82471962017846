//影响人员
<template>
  <div class="affect-persen px-4 py-2 d-flex flex-column">
    <div class="d-flex my-2 justify-content-between">
      <div>通知状态：</div>
      <div class="digiface cursor-pointer"
      v-on:click="showMsgs">{{affectPersen.Message_State}}</div>
    </div>
    <div class="d-flex justify-content-between my-2">
      <div>影响户数：</div>
      <div class="digiface">{{affectPersen.RenShu}}</div>
    </div>
    <div v-if="affectPersen.Tree.C" class="d-flex flex-column my-2">
      <div class="align-self-start">用户信息:</div>
      <topo-tree class="align-self-end" :topoTree="affectPersen.Tree"></topo-tree>
    </div>
  </div>
</template>
<script>
import topoTree from '@/components/project-initiative-repair/topo-tree.vue';

export default {
  name: 'affect-persen',
  components: {
    topoTree,
  },
  props: {
    isOpen: {
      default: 0,
      type: [Number, Boolean],
    },
    affectPersen: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    // 自动发送短信
    isAutoMsg: {
      get() {
        return this.$store.getter.isAutoMsg;
      },
      set() {},
    },
  },
  methods: {
    sendMsg() {
      this.$emit('sendMsg');
    },
    showMsgs() {
      if (this.affectPersen.Message_State === '未发送') {
        return;
      }
      this.$emit('showMsgs');
    },
  },
};
</script>
<style scoped>
.affect-persen,
.digiface {
  font-size: 0.9rem;
}
.my-2{
  padding:0.3rem 0;
}
</style>
