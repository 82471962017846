<template>
  <div class="msg-historys">
    <div class="msg-box msg-box-hidden">
      <div
        class="msg-content msg-container full-bg d-flex justify-content-between align-items-center"
        v-for="(warn,index) in filterUserMsgs(warnList)"
        :key="'warn-'+index"
      >
        <div class="alarm d-flex align-items-center">
          <p class="text-center">{{warn.StageName}}</p>
        </div>
        <div class="col-10 align-self-end">
          <div class="Notice-the-time">
            <label>
              <span>通知时间:</span>
              {{warn.SendTime}}
            </label>
          </div>
          <div class="Inform-the-content">
            <label>
              <span>通知内容:</span>
              {{warn.Content}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'msg-historys',
  props: {
    warnId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      warnList: [],
    };
  },
  mounted() {
    this.getWarnHistorys();
  },
  filters: {},
  methods: {
    filterUserMsgs(msgs) {
      return msgs.filter((msg) => msg.Receiver === '用电客户');
    },
    getWarnHistorys() {
      // events
      //   .JSON_request(
      //     events.MESSAGE_LIST,
      //     {
      //       EWIDstr: this.warnId,
      //     },
      //     events.ORDER_URL,
      //   )
      //   .then((response) => {
      //     this.warnList = response;
      //   })
      //   .catch((failInfo) => {
      //     console.log(failInfo);
      //   });
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.msg-box-hidden {
  overflow-y: auto;
}
.msg-container + .msg-container {
  margin-top: 1.2rem;
}
.msg-container {
  padding-left: 2rem;
  background-image: url("../../assets/images/project/initiative-repair/zfx.png");
}
.alarm {
  width: 6rem;
  height: 6rem;
  background-image: url("../../assets/images/project/initiative-repair/yq.png");
  background-size: 100% 100%;
  /* margin-bottom: 3%; */
  line-height: 6rem;
  float: left;
  font-size: 1.2rem;
  color: #01d1ff;
}
.alarm p {
  width: 60%;
  height: 100%;
  /* line-height: 90%; */
  line-height: normal;
  padding-top: 1.5rem;
  vertical-align: middle;
  margin: 0 auto;
}
.msg-box {
  padding: 0rem 8rem;
}
span {
  font-size: 20px;
  color: #4cb1ff;
  /* padding-left:15%; */
}
label {
  font-size: 15px;
}
.Notice-the-time {
  width: 80%;
  float: left;
  padding-top: 1.5rem;
  margin-left: 2rem;
  text-align: left;
}
.Inform-the-content {
  width: 80%;
  float: left;
  margin-left: 2rem;
  text-align: left;
}
</style>
