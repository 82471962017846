<template>
  <div class="err-devices-dia flex-grow-1 d-flex flex-wrap-reverse">
    <meter-item
      v-for="m in selectBoxMaxMetersNo"
      v-bind:meterData="selectMeters[m-1]"
      :key="m"
      v-bind:meterOrder="m"
      :class="'col-'+parseInt(12/selectBoxInfo.Lie)"
    ></meter-item>
  </div>
</template>
<script>
import meterItem from '@/components/utils/meter-item.vue';

export default {
  name: 'err-devices-dia',
  components: {
    meterItem,
  },
  data() {
    return {
      selectBoxInfo: {
        TabIDStr: 'QTU4NzhFRDJDNjRBRjA3OQ',
        SBMName: '3730005000000252624191',
        ZCBM: '3730005000000252624191',
        BXH: '01',
        Hang: '2',
        Lie: '2',
        DianBiaoShu: 7,
        JCX: [
          {
            id: 1, code: 'ia', name: 'A相电流', dw: 'A', Value: 0.638,
          },
          {
            id: 2, code: 'ib', name: 'B相电流', dw: 'A', Value: 0,
          },
          {
            id: 3, code: 'ic', name: 'C相电流', dw: 'A', Value: 0.469,
          },
          {
            id: 4, code: 'ua', name: 'A相电压', dw: 'V', Value: 224.1,
          },
          {
            id: 5, code: 'ub', name: 'B相电压', dw: 'V', Value: 223.7,
          },
          {
            id: 6, code: 'uc', name: 'C相电压', dw: 'V', Value: 224.2,
          },
          {
            id: 7, code: 'fha', name: 'A相有功功率', dw: 'kW', Value: 0.1195,
          },
          {
            id: 8, code: 'fhb', name: 'B相有功功率', dw: 'kW', Value: 0,
          },
          {
            id: 9, code: 'fhc', name: 'C相有功功率', dw: 'kW', Value: 0.0153,
          },
          {
            id: 10, code: 'pfa', name: 'A相功率因数', dw: null, Value: 0.996,
          },
          {
            id: 11, code: 'pfb', name: 'B相功率因数', dw: null, Value: 0,
          },
          {
            id: 12, code: 'pfc', name: 'C相功率因数', dw: null, Value: 0.264,
          },
          {
            id: 13,
            code: 'qa',
            name: 'A相无功功率',
            dw: 'kvar',
            Value: -0.0092,
          },
          {
            id: 14, code: 'qb', name: 'B相无功功率', dw: 'kvar', Value: 0,
          },
          {
            id: 15,
            code: 'qc',
            name: 'C相无功功率',
            dw: 'kvar',
            Value: -0.0575,
          },
          {
            id: 16, code: 'i0', name: '零序电流', dw: 'A', Value: 0.279,
          },
          {
            id: 17,
            code: 'zyggl',
            name: '总有功功率',
            dw: 'kW',
            Value: 0.1349,
          },
          {
            id: 18,
            code: 'zglys',
            name: '总功率因数',
            dw: null,
            Value: 0.7490000000000001,
          },
          {
            id: 19,
            code: 'zwggl',
            name: '总无功功率',
            dw: 'kvar',
            Value: -0.0668,
          },
          {
            id: 31,
            code: 'zygsz',
            name: '正向有功示值',
            dw: 'kWh',
            Value: 110.02,
          },
          {
            id: 32,
            code: 'zwgsz',
            name: '正向无功示值',
            dw: 'kvarh',
            Value: 21.2,
          },
          {
            id: 33,
            code: 'fygsz',
            name: '反向有功示值',
            dw: 'kWh',
            Value: 22.65,
          },
          {
            id: 34,
            code: 'fwgsz',
            name: '反向无功示值',
            dw: 'kvarh',
            Value: 62.55,
          },
          {
            id: 35,
            code: 'zygdd',
            name: '正向有功电度',
            dw: 'kWh',
            Value: 0.17,
          },
          {
            id: 36,
            code: 'zwgdd',
            name: '正向无功电度',
            dw: 'kvarh',
            Value: 0.01,
          },
          {
            id: 37, code: 'fygdd', name: '反向有功电度', dw: 'kWh', Value: 0,
          },
          {
            id: 38,
            code: 'fwgdd',
            name: '反向无功电度',
            dw: 'kvarh',
            Value: 0.02,
          },
          {
            id: 683, code: 'di1', name: '开入1', dw: null, Value: 1,
          },
          {
            id: 783,
            code: 'ZSZGL',
            name: '总视在功率',
            dw: 'kVA',
            Value: 0.1779,
          },
          {
            id: 784,
            code: 'sa',
            name: 'A相视在功率',
            dw: 'kVA',
            Value: 0.1195,
          },
          {
            id: 785, code: 'sb', name: 'B相视在功率', dw: 'kVA', Value: 0,
          },
          {
            id: 786, code: 'sc', name: 'C相视在功率', dw: 'kVA', Value: 0.0583,
          },
        ],
      },
      selectBoxMaxMetersNo: 4,
      selectMeters: [
        {
          TabIDStr: 'NjA1RDNBQUQ0RjQxMjM2MQ',
          Hang: '1',
          Lie: '1',
          YHM: '*振荣',
          HH: '0280515720',
          XH: '1',
          JCDBM: '5982',
          JCX: [
            {
              id: 1,
              code: 'ia',
              encode: 'B621',
              name: 'A相电流',
              dw: 'A',
              Value: 0.791,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 4,
              code: 'ua',
              encode: 'B611',
              name: 'A相电压',
              dw: 'V',
              Value: 224.2,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 7,
              code: 'fha',
              encode: 'B631',
              name: 'A相有功功率',
              dw: 'kW',
              Value: 0.1561,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 10,
              code: 'pfa',
              encode: 'B651',
              name: 'A相功率因数',
              dw: null,
              Value: 1,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 16,
              code: 'i0',
              encode: 'B620',
              name: '零序电流',
              dw: 'A',
              Value: 0.79,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 17,
              code: 'zyggl',
              encode: 'B630',
              name: '总有功功率',
              dw: 'kW',
              Value: 0.1561,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 18,
              code: 'zglys',
              encode: 'B650',
              name: '总功率因数',
              dw: null,
              Value: 1,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 31,
              code: 'zygsz',
              encode: '9010',
              name: '正向有功示值',
              dw: 'kWh',
              Value: 4205.44,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 33,
              code: 'fygsz',
              encode: '9020',
              name: '反向有功示值',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 35,
              code: 'zygdd',
              encode: '9010.1',
              name: '正向有功电度',
              dw: 'kWh',
              Value: 0.17,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 36,
              code: 'zwgdd',
              encode: '9110.1',
              name: '正向无功电度',
              dw: 'kvarh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 37,
              code: 'fygdd',
              encode: '9020.1',
              name: '反向有功电度',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 38,
              code: 'fwgdd',
              encode: '9120.1',
              name: '反向无功电度',
              dw: 'kvarh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 683,
              code: 'di1',
              encode: '9361.1',
              name: '开入1',
              dw: null,
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
          ],
        },
        {
          TabIDStr: 'NUY1OUEwRjM4MUFDMzI1Mw',
          Hang: '1',
          Lie: '2',
          YHM: '*朝军',
          HH: '0280816735',
          XH: '2',
          JCDBM: '5983',
          JCX: [
            {
              id: 1,
              code: 'ia',
              encode: 'B621',
              name: 'A相电流',
              dw: 'A',
              Value: 0.091,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 4,
              code: 'ua',
              encode: 'B611',
              name: 'A相电压',
              dw: 'V',
              Value: 223.7,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 7,
              code: 'fha',
              encode: 'B631',
              name: 'A相有功功率',
              dw: 'kW',
              Value: 0.0118,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 10,
              code: 'pfa',
              encode: 'B651',
              name: 'A相功率因数',
              dw: null,
              Value: 0.575,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 16,
              code: 'i0',
              encode: 'B620',
              name: '零序电流',
              dw: 'A',
              Value: 0.116,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 17,
              code: 'zyggl',
              encode: 'B630',
              name: '总有功功率',
              dw: 'kW',
              Value: 0.0118,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 18,
              code: 'zglys',
              encode: 'B650',
              name: '总功率因数',
              dw: null,
              Value: 0.575,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 31,
              code: 'zygsz',
              encode: '9010',
              name: '正向有功示值',
              dw: 'kWh',
              Value: 1324.11,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 33,
              code: 'fygsz',
              encode: '9020',
              name: '反向有功示值',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 35,
              code: 'zygdd',
              encode: '9010.1',
              name: '正向有功电度',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 36,
              code: 'zwgdd',
              encode: '9110.1',
              name: '正向无功电度',
              dw: 'kvarh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 37,
              code: 'fygdd',
              encode: '9020.1',
              name: '反向有功电度',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 38,
              code: 'fwgdd',
              encode: '9120.1',
              name: '反向无功电度',
              dw: 'kvarh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 683,
              code: 'di1',
              encode: '9361.1',
              name: '开入1',
              dw: null,
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
          ],
        },
        {
          TabIDStr: 'NTQ1QTI3RDkwN0NDNTg5OA',
          Hang: '2',
          Lie: '1',
          YHM: '',
          HH: '',
          XH: '3',
          JCDBM: '5981',
          JCX: [
            {
              id: 1,
              code: 'ia',
              encode: 'B621',
              name: 'A相电流',
              dw: 'A',
              Value: 0.201,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 4,
              code: 'ua',
              encode: 'B611',
              name: 'A相电压',
              dw: 'V',
              Value: 223.7,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 7,
              code: 'fha',
              encode: 'B631',
              name: 'A相有功功率',
              dw: 'kW',
              Value: 0.0229,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 10,
              code: 'pfa',
              encode: 'B651',
              name: 'A相功率因数',
              dw: null,
              Value: 0.8170000000000001,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 16,
              code: 'i0',
              encode: 'B620',
              name: '零序电流',
              dw: 'A',
              Value: 0.201,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 17,
              code: 'zyggl',
              encode: 'B630',
              name: '总有功功率',
              dw: 'kW',
              Value: 0.0229,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 18,
              code: 'zglys',
              encode: 'B650',
              name: '总功率因数',
              dw: null,
              Value: 0.8170000000000001,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 31,
              code: 'zygsz',
              encode: '9010',
              name: '正向有功示值',
              dw: 'kWh',
              Value: 716.55,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 33,
              code: 'fygsz',
              encode: '9020',
              name: '反向有功示值',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 35,
              code: 'zygdd',
              encode: '9010.1',
              name: '正向有功电度',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 36,
              code: 'zwgdd',
              encode: '9110.1',
              name: '正向无功电度',
              dw: 'kvarh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 37,
              code: 'fygdd',
              encode: '9020.1',
              name: '反向有功电度',
              dw: 'kWh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 38,
              code: 'fwgdd',
              encode: '9120.1',
              name: '反向无功电度',
              dw: 'kvarh',
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
            {
              id: 683,
              code: 'di1',
              encode: '9361.1',
              name: '开入1',
              dw: null,
              Value: 0,
              TIMESTAMP: '2019-03-29 07:45:29',
            },
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
</style>
