<template>
  <div class="work-order-detail d-flex flex-column pt-2 pb-4 px-4">
    <Backup class="align-self-start"></Backup>
    <work-steps
      :stepsInfo="orderInfo.ShiJianZhou"
      :activeStep="orderInfo.Stage"
      style="margin-bottom:1rem;"
    ></work-steps>
    <el-row class="flex-grow-1 d-flex position-relative" :gutter="12">
      <el-col :span="7" class="d-flex flex-column justify-content-between">
        <SectionFrame class="section-frame d-flex flex-column
        none-height flex-grow-1 mb-3">
          <div class="align-self-start section-title">报警信息</div>
          <work-particular-info
            class="flex-grow-1"
            :warnPartInfo="orderInfo.YuJingXinXi"
          ></work-particular-info>
        </SectionFrame>
        <SectionFrame class="section-frame
        d-flex flex-column none-height flex-grow-1 mt-3">
          <div class="align-self-start section-title">影响客户</div>
          <affect-persen
            @showMsgs="showMsgs"
            @showSendMsg="showSendMsg"
            :affectPersen="orderInfo.YingXiangKeHu"
            v-on:sendMsg="sendMsg"
            class="none-height flex-grow-1"
          ></affect-persen>
        </SectionFrame>
      </el-col>
      <el-col :span="10" class="d-flex">
        <SectionFrame class="section-frame d-flex flex-grow-1">
          <map-info
            v-on:showDia="showDiaInfo"
            :warnInfo="orderInfo.YuJingXinXi"
            :stage="orderInfo.Stage"
          ></map-info>
        </SectionFrame>
      </el-col>
      <el-col
        :span="7"
        class="d-flex flex-column"
        v-bind:class="[
          { 'justify-content-end': orderInfo.Stage == 0 },
          { 'justify-content-between': orderInfo.Stage > 0 },
        ]"
      >
        <SectionFrame class="section-frame">
          <div class="align-self-start section-title">通知人员</div>
          <AssignOrder
            :teams="orderInfo.YunWeiDuiWu"
            v-on:dispatchRepair="dispatchRepair"
            v-on:withdrawOrder="withdrawOrder"
            :stage="orderInfo.Stage"
          ></AssignOrder>
        </SectionFrame>
        <SectionFrame class="section-frame" v-if="orderInfo.Stage > 0">
            <div class="align-self-start section-title">工单信息</div>
            <work-feedback-info
              v-on:showDia="showDiaInfo"
              :steps="orderInfo.ShiJianZhou"
              :orderInfo="orderInfo.GongDanXinXi"
              :activeStep="orderInfo.Stage"
            ></work-feedback-info>
        </SectionFrame>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="showDia"
      :title="dialogTitle"
      class="dark"
    >
      <AssignOrderDia
        v-if="diaType == 0"
        :eId="eId"
         style="min-height:500px;"
        @cancelAssign="showDia = false"
        :selectTeam="selectTeam"
        v-on:assignedRepair="getAssigned"
        :work_message="orderInfo.YunWeiDuiWu.Message_Template"
        :usr_message="orderInfo.YingXiangKeHu.Message_Template"
      ></AssignOrderDia>
      <order-review-dia
        :orderId="oId"
        style="min-height:500px;"
        v-on:auditOrder="auditOrder"
        :orderInfo="orderInfo.GongDanXinXi"
        :activeStep="orderInfo.Stage"
        :steps="orderInfo.ShiJianZhou"
        v-else-if="diaType == 1"
      ></order-review-dia>
      <err-devices-dia v-else-if="diaType == 2"></err-devices-dia>
      <msg-historys v-else-if="diaType == 4" :warnId="eId"></msg-historys>
      <send-msg-dia
        v-else-if="diaType == 5"
        :eId="eId"
        v-on:assignedMsg="assignedMsg"
        :affectPersen="orderInfo.YingXiangKeHu"
      ></send-msg-dia>
    </el-dialog>
  </div>
</template>
<script>
import workSteps from '@/components/project-initiative-repair/work-steps.vue';
import mapInfo from '@/components/project-initiative-repair/map-info.vue';
import SectionFrame from '@/components/utils/section-frame.vue';
import workParticularInfo from '@/components/project-initiative-repair/work-particular-info.vue';
import workFeedbackInfo from '@/components/project-initiative-repair/work-feedback-info.vue';
import affectPersen from '@/components/project-initiative-repair/affect-persen.vue';
import AssignOrder from '@/components/project-initiative-repair/assign-order.vue';
import AssignOrderDia from '@/components/project-initiative-repair/assign-order-dia.vue';
import orderReviewDia from '@/components/project-initiative-repair/order-review-dia.vue';
import errDevicesDia from '@/components/project-initiative-repair/err-devices-dia.vue';
import sendMsgDia from '@/components/project-initiative-repair/send-msg-dia.vue';
import msgHistorys from '@/components/project-initiative-repair/msg-historys.vue';
import Backup from '@/components/utils/backup.vue';
import { getOrderDetail, getWithdrawOrder } from '@/api/project/initiative-repair';
import { ORDER_STAGE_MAP } from '@/utils/consts';

export default {
  name: 'work-order-detail',
  components: {
    workSteps,
    mapInfo,
    SectionFrame,
    workParticularInfo,
    workFeedbackInfo,
    affectPersen,
    AssignOrder,
    AssignOrderDia,
    orderReviewDia,
    errDevicesDia,
    sendMsgDia,
    msgHistorys,
    Backup,
  },
  data() {
    return {
      showDia: false,
      dialogTitle: '工单分派',
      diaType: 0,
      eId: '',
      oId: '',
      orderInfo: '',
      selectTeam: '',
      orderId: '',
      routeName: '',
    };
  },
  mounted() {
    this.routeName = this.$route.name;
    this.getPathDetail();
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.orderId);
    next();
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
  },
  watch: {
    diaType(newVal) {
      switch (newVal) {
        case 0:
          this.dialogTitle = '工单分派';
          return;
        case 1:
          this.dialogTitle = '工单审核';
          return;
        case 2:
          this.dialogTitle = '相关信息';
          return;
        case 4:
          this.dialogTitle = '客户通知历史';
          return;
        default:
          this.dialogTitle = '';
      }
    },
    $route() {
      this.getPathDetail();
    },
    orderInfo: {
      deep: true,
      handler() {},
    },
  },
  methods: {
    showMsgs() {
      this.showDiaInfo(4);
    },
    showSendMsg() {
      this.showDiaInfo(5);
    },
    // 分派工单
    dispatchRepair(team) {
      this.selectTeam = team;
      this.showDiaInfo(0);
    },
    assignedMsg() {
      // 工单已发送
      this.showDia = false;
    },
    sendMsg() {
      this.diaType = 3;
      this.showDia = true;
    },
    // 撤回工单
    withdrawOrder() {
      getWithdrawOrder({
        UIDstr: this.accountId,
        OrderIDstr: this.oId,
      }).then(() => {
        this.requestOrderDetail();
      });
    },
    getAssigned(orderId) {
      this.showDia = false;
      this.oId = orderId;
      this.requestOrderDetail();
    },
    auditOrder() {
      this.showDia = false;
      this.requestOrderDetail();
    },
    getPathDetail() {
      this.eId = this.$route.params.eId;
      this.oId = this.$route.params.oId;
      this.requestOrderDetail();
    },
    showDiaInfo(type) {
      this.diaType = type;
      this.showDia = true;
    },
    requestOrderDetail() {
      clearTimeout(this.orderId);
      if (!this.eId) {
        return;
      }
      getOrderDetail({
        EWIDstr: this.eId,
        OrderIDstr: this.oId,
      })
        .then(
          (res) => {
            const stageNo = ORDER_STAGE_MAP.get(res.Stage) - 1;
            // 重新定义相关字段
            // eslint-disable-next-line no-param-reassign
            res.Stage = stageNo >= 0 ? stageNo : 0;
            this.orderInfo = res;
          },
          (failCb) => {
            console.log(failCb);
          },
        )
        .then(() => {
          if (this.routeName !== this.$route.name) {
            return;
          }
          this.orderId = setTimeout(this.requestOrderDetail, 10 * 1000);
        });
    },
  },
};
</script>
<style scoped>
.work-order-detail {
  background-color: transparent;
  color: white;
}
.left_ {
  flex: 0 0 24%;
  margin-right: 1%;
}
.right_ {
  flex: 0 0 24%;
  margin-left: 1%;
}
.section-frame+.section-frame{
  margin-top:1rem;
}
.section-title{
  font-size: 1.2rem;
}
</style>
