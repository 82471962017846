<template>
  <div class="failure-confirm d-flex flex-column">
    <div v-if="confirmInfo.YuJiXiuFuShiJian" class="d-flex justify-content-between">
      <div>预计修复时间:</div>
      <div class="digiface">{{confirmInfo.YuJiXiuFuShiJian}}</div>
    </div>
    <div v-if="confirmInfo.GuZhangLeiXing" class="d-flex justify-content-between">
      <div>故障类型:</div>
      <div>{{confirmInfo.GuZhangLeiXing}}</div>
    </div>
    <div v-if="confirmInfo.GuZhangXianXiang" class="d-flex justify-content-between">
      <div>故障现象:</div>
      <div>{{confirmInfo.GuZhangXianXiang}}</div>
    </div>
    <div v-if="confirmInfo.XianChangFenLei" class="d-flex justify-content-between">
      <div>现场分类:</div>
      <div>{{confirmInfo.XianChangFenLei}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div>危害程度:</div>
      <div>{{confirmInfo.WeiHaiChengDu}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div>产权属性:</div>
      <div>{{confirmInfo.ChanQuanShuXing}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div>故障归属:</div>
      <div>{{confirmInfo.GuZhangGuiShu}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'confirm-fault',
  props: {
    confirmInfo: {
      type: Object,
      default() {
        return {
          GDIDstr: 'MzE0QjVFQjAxRkJCMjA5OQ',
          GongDanZhuangTai: '已到达现场',
          GongDanShiChang: '',
          FanKuiNeiRong: '额',
          TuPian: null,
          YuJiXiuFuShiJian: '',
          GuZhangLeiXing: '用电质量',
          GuZhangXianXiang: '单户停电',
          XianChangFenLei: '城市',
          WeiHaiChengDu: '单户',
          ChanQuanShuXing: '供电企业资产',
          GuZhangGuiShu: '配电',
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.digiface {
  font-size: 0.8rem;
}
</style>
