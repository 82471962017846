// 分派工单
<template>
  <div class="assign-order mx-4 my-2">
    <div v-if="stage<=4" class="d-flex justify-content-between">
      <div>分派状态:</div>
      <div class="digiface fenpai">{{teams.FenPaiZhuangTai?teams.FenPaiZhuangTai:"未分派"}}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="section-title align-self-start">运维队伍:</div>
      <div>
        <repair-team
        class="fenpai"
          v-on:dispatchRepair="dispatchRepair"
          v-on:withdrawOrder="$emit('withdrawOrder')"
          v-for="(team,index) in teams.DuiWuList"
          :key="'team-'+index"
          :teamInfo="team"
          :stage="stage"
        ></repair-team>
      </div>
    </div>
  </div>
</template>
<script>
import repairTeam from '@/components/project-initiative-repair/repair-team.vue';

export default {
  name: 'assign-order',
  components: {
    repairTeam,
  },
  props: {
    stage: {
      default: 0,
      type: Number,
    },
    teams: {
      default() {
        return {
          FenPaiZhuangTai: '已分派',
          DuiWuList: [
            {
              DuiWu: '开发分中心',
              RenYuanList: [
                {
                  Name: '测试',
                  UIDstr: 'OTVCREZENjRCMkYzRDMxMQ',
                  Phone: '232321',
                },
                {
                  Name: '屠玉录',
                  UIDstr: 'NDMzOEQ4QUJENDVEMDNGNQ',
                  Phone: '15905347338',
                },
                {
                  Name: '曾祥东',
                  UIDstr: 'Qjk1Nzc2MURCMEJGODhCMA',
                  Phone: '15853458228',
                },
              ],
              TeamIDstr: 'MjhCOTY0Qjc5Q0EyMjA1Ng',
            },
            {
              DuiWu: '营业室（黄河涯供电所）',
              RenYuanList: [
                {
                  Name: '蔡青孝',
                  UIDstr: 'REVEODQzMDdFODU3RTQ5Rg',
                  Phone: '15092586266',
                },
                {
                  Name: '宋振刚',
                  UIDstr: 'QTVCNEIwMjYxMTMzQzlGRA',
                  Phone: '13791337199',
                },
              ],
              TeamIDstr: 'NjFENjcwQTUyODUxMTU0MQ',
            },
          ],
        };
      },
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    dispatchRepair(team) {
      console.log('dispatchRepair', team);
      this.$emit('dispatchRepair', team);
    },
  },
};
</script>
<style scoped>
.assign-order,
.digiface {
  font-size: 0.8rem;
}
.section-title{
  font-size: 1.2rem;
}
.assign-order{
padding:2% 0;

}
.fenpai{
  padding:1% 0;
}
</style>
