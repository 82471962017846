<template>
  <div class="topo-tree">
    <div class="d-flex justify-content-between">
      <div>{{topoTree.Name}}</div>
      <div v-if="topoTree.C.length>0">
        <span
          class="iconfont"
          style="font-size:1.0rem;"
          :class="[{'icon-arrow-right':!isShow},{'icon-arrow-down':isShow}]"
          v-on:click="isShow=Math.abs(isShow-1)"
        ></span>
      </div>
    </div>
    <transition name="fade">
      <div class="topo-branch ml-4" v-if="topoTree.C.length>0" v-show="isShow">
        <topo-tree v-for="(branch,index) in topoTree.C"
         :key="'branch-'+index" :topoTree="branch"></topo-tree>
      </div>
    </transition>
  </div>
</template>
<script>

export default {
  name: 'topo-tree',
  props: {
    isOpen: {
      default: 0,
      type: [Number, Boolean],
    },
    topoTree: {
      type: Object,
      default() {
        return {
          TabIDstr: 'NTAxRjMyNDI2QzM0OTJGMA',
          Name: '7号表箱',
          C: [
            {
              TabIDstr: 'RDIyMzYzQkJDOTYzRjVGNA',
              Name: '*振兴',
              C: [],
            },
            {
              TabIDstr: 'NTY0Qzk5M0Q2MDFCOEFBMQ',
              Name: '*长青',
              C: [],
            },
            {
              TabIDstr: 'NzdBQjNEREVGMTExMkQ3Ng',
              Name: '*长青',
              C: [],
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      isShow: 0,
    };
  },
  mounted() {
    this.isShow = this.isOpen;
  },
  watch: {
    isOpen() {
      this.isShow = this.isOpen;
    },
  },
};
</script>
