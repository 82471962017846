// 工单详情信息
<template>
  <div class="work-particular-info d-flex flex-column justify-content-around px-4 py-2">
    <div class="warn-header text-warning align-self-start">{{warnPartInfo.DiZhi}}</div>
    <div v-if="warnPartInfo.Remark" class="d-flex justify-content-between">
      <div class>所属表箱:</div>
      <div class="text-warning">{{warnPartInfo.Remark}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div class>告警级别:</div>
      <div class="text-warning">{{warnPartInfo.JiBie}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div>告警类型:</div>
      <div class="text-warning">{{warnPartInfo.LeiXing}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div style="min-width:4rem;">告警范围:</div>
      <div class="text-warning text-right">{{warnPartInfo.FanWei}}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div style="min-width:4rem;" class="text-left">告警值:</div>
      <div class="text-warning text-right" v-html="warnPartInfo.Reason_Value"></div>
    </div>
    <div class="d-flex justify-content-between">
      <div>告警时间:</div>
      <div class="digiface text-warning">{{warnPartInfo.ShiJian}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'work-particular-info',
  props: {
    warnPartInfo: {
      type: Object,
      default() {
        return {
          DiZhi: '银座好望角---6B1进线开关',
          SuoShuXiangMu: '银座好望角',
          JiBie: '一般',
          LeiXing: '',
          FanWei: '局面',
          ShiJian: '2019-03-12 15:22:25',
          ChiXu: '',
          Lng: '117.010429382324',
          Lat: '36.6744613647461',
          Remark: '1#表箱',
        };
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  filters: {
    filterDuration(info) {
      if (info.ChiXu) {
        return info.ChiXu;
      }
      const warnTime = Date.parse(info.ShiJian);
      const now = Date.now();
      const durationMilis = now - warnTime;
      return Math.round(durationMilis / 1000 / 60);
    },
  },
};
</script>
<style scoped>
.work-particular-info,
.digiface {
  font-size: 0.9rem;
}
.warn-header {
  font-size: 1rem;
}
</style>
