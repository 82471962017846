<template>
  <div
    class="person-container d-inline px-2 py-1 mx-2 my-2"
    v-bind:class="[{'cursor-pointer':selectable},
    {'text-secondary':isSelected==0},
    {'text-warning':isSelected}]"
    v-on:click="changeSelect"
  >{{personInfo.Name}}</div>
</template>
<script>
export default {
  name: 'person-container',
  props: {
    isDetail: {
      default: 1,
      type: Number,
    },
    personInfo: {
      default() {
        return {
          Name: '测试',
          UIDstr: 'OTVCREZENjRCMkYzRDMxMQ',
          Phone: '232321',
        };
      },
      type: Object,
    },
    selectable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isSelected: 1,
    };
  },
  methods: {
    changeSelect() {
      if (this.isDetail) {
        return;
      }

      this.isSelected = this.isSelected ? 0 : 1;
      this.$emit('togglePerson', this.isSelected);
    },
  },
};
</script>
<style scoped>
.person-container {
  font-size: 0.8rem;
  border: solid 1px rgba(127, 127, 240, 0.6);
  border-radius: 4px;
}
</style>
