import { render, staticRenderFns } from "./assign-order-dia.vue?vue&type=template&id=7b10cf54&scoped=true&"
import script from "./assign-order-dia.vue?vue&type=script&lang=js&"
export * from "./assign-order-dia.vue?vue&type=script&lang=js&"
import style0 from "./assign-order-dia.vue?vue&type=style&index=0&id=7b10cf54&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b10cf54",
  null
  
)

export default component.exports