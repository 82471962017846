<template>
  <div class="work-step d-flex flex-column">
    <div
      class="step-name d-flex justify-content-center align-items-center"
      v-bind:class="[{ 'text-warning': isActive }, { active: isActive }]"
    >
      {{ stepInfo.Text }}
    </div>
    <div class="step-time text-warning"
      v-html="stepInfo.Time ? stepInfo.Time : '&nbsp;&nbsp;' ">
    </div>
  </div>
</template>
<script>
export default {
  name: 'work-step',
  props: {
    stepInfo: {
      default() {
        return {
          Text: '工单派发',
          Time: '2019-03-11 15:58:01',
          TimeSpan: '9983.65',
        };
      },
      type: Object,
    },
    isActive: {
      default: 1,
      type: [Number, Boolean],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.step-name {
  cursor: pointer;
  width: 120px;
  height: 36px;
  background: #192e7a;
  border: 1px solid #25c1ff;
  opacity: 0.45;
  border-radius: 2px;
  color: #ccc;
}
.step-time {
  font-size: 12px;
  color:#778ED3;
}
.step-name.active {
  font-size: 0.9rem;
  color: #fff;
}
</style>
