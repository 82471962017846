<template>
  <div class="map-info-container d-flex flex-grow-1">
    <div id="map-info" class="map-info flex-grow-1"></div>
  </div>
</template>
<script>
import { STAGE_MAP } from '@/utils/consts';
/* eslint-disable no-undef */
export default {
  name: 'map-info',
  props: {
    stage: {
      default: 0,
      type: Number,
    },
    warnInfo: {
      type: Object,
      default() {
        return {
          DiZhi: '银座好望角---6B1进线开关',
          SuoShuXiangMu: '银座好望角',
          JiBie: '一般',
          LeiXing: '',
          FanWei: '局面',
          ShiJian: '2019-03-12 15:22:25',
          ChiXu: '',
          Lng: '117.010429382324',
          Lat: '36.6744613647461',
        };
      },
    },
  },
  data() {
    return {
      heatmap: '',
      mapCenter: [],
      gInnerLocs: '',
      gOuterLocs: '',
      bermudaTriangle: null,
      isInnerReady: false,
      isOuterReady: false,
      infoWin: '',
    };
  },
  mounted() {
    this.changeCenter(this.warnInfo);
  },
  computed: {
    selectArea: {
      get() {
        return this.$store.getter.selectArea;
      },
      set() {},
    },
  },
  watch: {
    warnInfo: {
      deep: true,
      handler(newVal) {
        this.changeCenter(newVal);
      },
    },
    mapCenter: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.heatmap && newVal[1] && oldVal.length > 0) {
          this.heatmap.panTo(
            new T.LngLat(parseFloat(newVal[0]), parseFloat(newVal[1])),
          );
          this.initInfoWin();
        } else {
          this.initMap();
        }
      },
    },
    heatData: {
      deep: true,
      handler() {},
    },
  },
  methods: {
    getZoomSize(type) {
      switch (type) {
        case 1:
          return 10;
        case 2:
          return 9;
        case 3:
          return 15;
        default:
          return 5;
      }
    },
    changeCenter(warnInfo) {
      this.mapCenter = [parseFloat(warnInfo.Lng), parseFloat(warnInfo.Lat)];
    },
    changeToGLocs(polyline) {
      const pointsArr = polyline.split(';');
      const gLocs = [];
      pointsArr.forEach((point) => {
        const pointArr = point.split(',');
        const gPoint = {
          lng: parseFloat(pointArr[0]),
          lat: parseFloat(pointArr[1]),
        };
        gLocs.push(gPoint);
      });
      this.gInnerLocs = gLocs;
    },
    initMap() {
      const imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?'
        + 'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles'
        + '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0b9199b328f9862d3ec9c14d8c020f19';
      const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
      this.heatmap = new T.Map('map-info', {
        center: new T.LngLat(...this.mapCenter),
        minZoom: 10,
        maxZoom: 18,
        zoom: 17,
        layers: [lay],
      });
      this.initInfoWin();
    },
    initInfoWin() {
      if (this.infoWin) {
        this.heatmap.removeOverLay(this.infoWin);
        this.infoWin = '';
      }
      const infoContainer = document.createElement('div');
      infoContainer.className = 'd-flex flex-column align-items-stretch';
      const infoMainContainer = document.createElement('div');
      infoMainContainer.className = 'warn-info';
      const titleContainer = document.createElement('div');
      titleContainer.className = 'info-top d-flex justify-content-between align-items-center';
      const titleD = document.createElement('div');
      const closeX = document.createElement('img');
      titleD.className = 'pl-2';
      closeX.className = 'm-2 ml-4 align-self-start';
      titleD.innerHTML = `<div class="font-weight-bold">${this.warnInfo.DiZhi}</div>`;
      titleContainer.appendChild(titleD);
      titleContainer.appendChild(closeX);
      closeX.onclick = this.closeInfoWindow;
      infoMainContainer.appendChild(titleContainer);
      const contentContainer = document.createElement('div');
      contentContainer.className = 'py-1 px-2';
      const contentString = `
      <div class="d-flex justify-content-between"><div>预警时间:</div><div>${
  this.warnInfo.ShiJian
}</div></div>
      <div class="d-flex justify-content-between"><div>预警类型:</div><div>${
  this.warnInfo.LeiXing
}</div></div>
      <div class="d-flex justify-content-between"><div>预警级别:</div><div>${
  this.warnInfo.JiBie
}</div></div>
      <div class="d-flex justify-content-between"><div>状态:</div><div>${STAGE_MAP.get(
    this.stage,
  )}</div></div>`;
      contentContainer.innerHTML = contentString;
      infoMainContainer.appendChild(contentContainer);
      infoContainer.appendChild(infoMainContainer);
      this.infoWin = new T.InfoWindow();
      this.infoWin.setContent(infoContainer);
      this.infoWin.setLngLat(
        new T.LngLat(...this.mapCenter),
      );
      // 显示详情点击事件
      this.heatmap.addOverLay(this.infoWin);
    },
    closeInfoWindow() {
      this.heatmap.clearInfoWindow();
    },
  },
};
</script>
<style >
.map-info,
.warn-info {
  color: rgb(101, 101, 247);
}
.warn-info {
  background-color: white !important;
}
</style>
