// 工单反馈信息
<template>
  <div class="work-feedback-info d-flex flex-column px-4 py-2">
    <div class="work-section d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div>工单状态:</div>
        <div class="text-success">{{orderInfo.GongDanZhuangTai}}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div>工单时长:</div>
        <div>
          <span class="digiface">{{getDuration()}}</span>min
        </div>
      </div>
      <template v-if="activeStep>=4">
        <template v-if="steps.length==7">
          <div class="d-flex flex-column align-items-left">
            <div class="text-left">修复信息:</div>
            <div class="ml-4 text-left">{{orderInfo.XiuFuXinXi}}</div>
          </div>
          <div class="d-flex flex-column align-items-left">
            <div class="text-left">客户反馈:</div>
            <div class="ml-4 text-left">{{orderInfo.KeHuFanKui}}</div>
          </div>
          <div
            v-if="orderInfo.TuPian&&orderInfo.TuPian.length>1"
            class="d-flex flex-column align-items-left"
          >
            <div class="text-left">上传的图片</div>
            <div></div>
          </div>
        </template>
        <div v-else class="d-flex flex-column align-items-left">
          <div class="text-left">反馈内容:</div>
          <div class="ml-4 text-left">{{orderInfo.FanKuiNeiRong}}</div>
        </div>
      </template>
    </div>
    <failure-confirm v-if="activeStep>=3" :confirmInfo="orderInfo"></failure-confirm>
    <div v-if="isDetail&&(activeStep==4||activeStep==6)" class="text-right">
      <button v-on:click="$emit('showDia',1)"
      class="btn-check btn btn-outline-primary btn-sm">审核</button>
    </div>
  </div>
</template>
<script>
import failureConfirm from '@/components/project-initiative-repair/failure-confirm.vue';

export default {
  name: 'work-feedback-info',
  components: {
    failureConfirm,
  },
  props: {
    steps: {
      default() {
        return [];
      },
      type: Array,
    },
    isDetail: {
      default: 1,
      type: Number,
    },
    activeStep: {
      default: 0,
      type: Number,
    },
    orderInfo: {
      type: Object,
      default() {
        return {
          GDIDstr: 'MzE0QjVFQjAxRkJCMjA5OQ',
          GongDanZhuangTai: '已到达现场',
          GongDanShiChang: '',
          FanKuiNeiRong: '额',
          TuPian: null,
          YuJiXiuFuShiJian: '',
          GuZhangLeiXing: '用电质量',
          GuZhangXianXiang: '单户停电',
          XianChangFenLei: '城市',
          WeiHaiChengDu: '单户',
          ChanQuanShuXing: '供电企业资产',
          GuZhangGuiShu: '配电',
        };
      },
    },
  },
  watch: {
    steps: {
      deep: true,
      handler() {},
    },
  },
  methods: {
    getDuration() {
      if (this.steps.length < 2) {
        return 0;
      }
      const firstTime = Date.parse(this.steps[0].Time);
      const lastTime = Date.parse(this.getLastTime());
      const durMilis = lastTime - firstTime;
      return Math.round(durMilis / (1000 * 60));
    },
    getLastTime() {
      const order = this.steps.findIndex(
        (step, index, arr) => step.Time
        && (index === arr.length - 1
        || !arr[index + 1].Time),
      );
      return this.steps[order].Time;
    },
  },
};
</script>
<style scoped>
.work-feedback-info,
.digiface {
  font-size: 0.9rem;
}
.btn-check {
  font-size: 0.6rem;
}
</style>
