<template>
  <div class="assign-order-dia d-flex flex-grow-1">
    <div class="col-6 d-flex flex-column justify-content-between">
      <SectionFrame class="mb-1">
        <div>影响人员</div>
      </SectionFrame>
      <SectionFrame class="none-height flex-grow-1 px-4 py-2 overflow-y">
        <topo-tree
          :isOpen="parseInt(1)"
          class="ml-4 align-self-end digiface"
          :topoTree="affectPersen.Tree"
        ></topo-tree>
      </SectionFrame>
    </div>
    <div class="col-6 d-flex flex-column">
      <SectionFrame class="mb-1">
        <div>短信内容</div>
      </SectionFrame>
      <SectionFrame class="flex-grow-1 d-flex flex-column">
        <div class="flex-grow-1 bg-transparent p-2 text-white text-left">{{tem_msg}}</div>
      </SectionFrame>
      <button class="btn btn-primary btn-sm mt-1" v-on:click="assignMsg">提交</button>
    </div>
  </div>
</template>
<script>
import topoTree from '@/components/project-initiative-repair/topo-tree.vue';
import SectionFrame from '@/components/utils/section-frame.vue';

export default {
  name: 'assign-order-dia',
  components: {
    SectionFrame,
    topoTree,
  },
  props: {
    tem_msg: {
      type: String,
      default: '',
    },
    affectPersen: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    eId: {
      type: String,
      default: '',
    },
    selectTeam: {
      type: Object,
      default() {
        return {
          DuiWu: '开发分中心',
          RenYuanList: [
            {
              Name: '测试',
              UIDstr: 'OTVCREZENjRCMkYzRDMxMQ',
              Phone: '232321',
            },
            {
              Name: '屠玉录',
              UIDstr: 'NDMzOEQ4QUJENDVEMDNGNQ',
              Phone: '15905347338',
            },
            {
              Name: '曾祥东',
              UIDstr: 'Qjk1Nzc2MURCMEJGODhCMA',
              Phone: '15853458228',
            },
          ],
          TeamIDstr: 'MjhCOTY0Qjc5Q0EyMjA1Ng',
        };
      },
    },
  },
  data() {
    return {
      msgContent: '',
    };
  },
  computed: {
    accountId: {
      get() {
        return this.$store.getter.accountId;
      },
      set() {},
    },
  },
  watch: {
    affectPersen: {
      deep: true,
      handler() {},
    },
    seletedPersenTeam: {
      deep: true,
      handler() {},
    },
    selectTeam(newVal) {
      this.seletedPersenTeam = newVal;
    },
  },
  methods: {
    changeSelect(isSelect, order) {
      this.$set(
        this.seletedPersenTeam.RenYuanList[order],
        'isSelect',
        isSelect,
      );
    },
    // 分派维修队伍
    assignMsg() {
      if (!this.msgContent) {
        // window.alert('请输入短信内容!');
        return;
      }
      console.log(this.msgContent);
      // events
      //   .JPTQ_request(events.MOCK_SEND_MSG, {
      //     GDIDstr: this.eId,
      //     CIDs: '',
      //   })
      //   .then((response) => {
      //     this.$emit('assignedMsg', response.OrderIDstr);
      //   });
    },
    getSelectedIds() {
      const selectIds = [];
      this.seletedPersenTeam.RenYuanList.forEach((person) => {
        if (!Object.prototype.hasOwnProperty.call(person, 'isSelect')) {
          selectIds.push(person.UIDstr);
        } else if (person.isSelect) {
          selectIds.push(person.UIDstr);
        }
      });
      return selectIds;
    },
  },
};
</script>
<style scoped>
.assign-order-dia {
  font-size: 0.8rem;
}
</style>
