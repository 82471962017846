//工单审核dialog
<template>
    <div class="order-review-dia flex-grow-1 d-flex flex-column">
        <div class="d-flex flex-column none-height flex-grow-1">
            <SectionFrame class="w-25 mb-1">
                工单信息
            </SectionFrame>
            <SectionFrame class="flex-grow-1 " style="overflow-y:auto;">
                <WorkFeedbackInfo :steps="steps"
                :activeStep="activeStep"
                v-bind:isDetail=0 :orderInfo="orderInfo"></WorkFeedbackInfo>
            </SectionFrame>
        </div>
        <div class="d-flex justify-content-end mt-2">
            <div class="d-flex justify-content-end mt-2">
                <button class="btn btn-outline-secondary
                btn-sm" v-on:click="auditOrder(2)">审核不通过</button>
                <button class="btn btn-primary btn-sm ml-4" v-on:click="auditOrder(1)">审核通过</button>
            </div>
        </div>
    </div>
</template>
<script>
import SectionFrame from '@/components/utils/section-frame.vue';
import WorkFeedbackInfo from '@/components/project-initiative-repair/work-feedback-info.vue';
import { getCheckOrder } from '@/api/project/initiative-repair';

export default {
  name: 'order-review-dia',
  components: {
    SectionFrame,
    WorkFeedbackInfo,
  },
  props: {
    orderId: {
      default: '',
      type: String,
    },
    steps: {
      default() {
        return [];
      },
      type: Array,
    },
    orderInfo: {
      default() {
        return {

        };
      },
      type: Object,
    },
    activeStep: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
  },
  methods: {
    auditOrder(type) {
      const params = {
        UIDstr: this.accountId,
        GDstr: this.orderId,
        ReviewState: type,
        IS_SendMessage: 0,
      };
      console.log('params', params);
      getCheckOrder(params).then(() => {
        this.$emit('auditOrder', type);
      });
    },
  },
};
</script>
<style scoped>
</style>
