const SYS_NAME = '正泰物联网传感产业园智能台区系统';

const COMPANY_NAME = '电亮亮信息科技有限公司';
const PROPERTY_RIGHTS_TYPES = [{
  label: '用户',
  value: 0,
}, {
  label: '供电公司',
  value: 1,
}];
const BUSINESS_TYPES = [{
  label: '电',
  value: 'BusinessType_electricity',
}, {
  label: '水',
  value: 'BusinessType_water',
}, {
  label: '天然气',
  value: 'BusinessType_gas',
}];
const EQUIP_SUBENTRY_TYPES = [{
  label: '业主',
  value: 'DeviceItemType_xc_YZ',
}, {
  label: '公用照明',
  value: 'DeviceItemType_xc_GYZM',
}, {
  label: '弱电间',
  value: 'DeviceItemType_xc_RDJ',
}, {
  label: '电梯',
  value: 'DeviceItemType_xc_DT',
}, {
  label: '设备',
  value: 'DeviceItemType_xc_SB',
}, {
  label: '应急照明',
  value: 'DeviceItemType_xc_ZKSXF',
}];
const ORDER_STAGE_MAP = new Map([
  ['', 0],
  ['工单派发', 1],
  ['到达现场', 2],
  ['故障确认', 3],
  ['抢修完成', 4],
  ['工单审核', 5],
  ['反馈', 6],
  ['工单归档', 8],
]);
const STAGE_MAP = new Map(
  [
    [0, '新预警'],
    [1, '工单派发'],
    [2, '到达现场'],
    [3, '故障确认'],
    [4, '抢修完成'],
    [5, '已归档'],
    [6, '打回'],
  ],
);
export {
  SYS_NAME,
  COMPANY_NAME,
  PROPERTY_RIGHTS_TYPES,
  BUSINESS_TYPES,
  EQUIP_SUBENTRY_TYPES,
  ORDER_STAGE_MAP,
  STAGE_MAP,
};
