<template>
  <tr class="meter-container">
    <td>{{meterOrder}}</td>
    <td>{{meterInfo.YHM?meterInfo.YHM:"无"}}</td>
    <td>{{meterInfo.HH?meterInfo.HH:'无'}}</td>
    <td>{{meterInfo.meterNo?meterInfo.meterNo:'00000.00'}}</td>
    <td>{{meterInfo.activePower?meterInfo.activePower:0}}</td>
    <td>{{meterInfo.dianY?meterInfo.dianY:'无'}}</td>
    <td>{{meterInfo.dianL?meterInfo.dianL:'无'}}</td>
  </tr>
</template>
<script>
// 电表Item
export default {
  name: 'meter-item',
  props: {
    meterOrder: {
      default: 0,
    },
    meterData: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      meterNo: '000000',
      type: 0,
      meterInfo: {},
    };
  },
  mounted() {
    this.type = this.meterData.YHM ? 0 : 2;
    this.setMeterData(this.meterData);
  },
  watch: {
    meterData: {
      deep: true,
      handler(newVal) {
        this.setMeterData(newVal);
      },
    },
  },
  filters: {},
  computed: {
    filterMeterNo() {
      switch (parseInt(this.type, 10)) {
        case 0:
          return this.meterNo;
        case 1:
          return this.meterNo;
        case 2:
          return '000000';
        default:
          return this.meterNo;
      }
    },
  },
  methods: {
    setMeterData(newVal) {
      const meterInfo = {};
      meterInfo.TabIDStr = newVal.TabIDStr;
      meterInfo.YHM = newVal.YHM;
      meterInfo.HH = newVal.HH;
      meterInfo.XH = newVal.XH;
      this.type = newVal.YHM ? 0 : 2;
      this.getMeterInfo(meterInfo, newVal.JCX);
    },
    getMeterInfo(meterInfo, infoList) {
      if (!infoList) {
        return;
      }
      infoList.forEach((info) => {
        this.setMeterInfo(meterInfo, info);
      });

      this.meterInfo = meterInfo;
    },
    setMeterInfo(meterInfo, info) {
      switch (info.id) {
        case 172:
          // eslint-disable-next-line no-param-reassign
          meterInfo.dianY = this.getValueByNo(info);
          break;
        case 175:
          // eslint-disable-next-line no-param-reassign
          meterInfo.dianL = this.getValueByNo(info);
          break;
        case 188:
          // eslint-disable-next-line no-param-reassign
          meterInfo.activePower = this.getValueByNo(info);
          break;
        case 202:
          // eslint-disable-next-line no-param-reassign
          meterInfo.meterNo = this.getFixLengthNo(info.Value, 8);
          break;
        case 212:
          // eslint-disable-next-line no-param-reassign
          meterInfo.onOffState = parseInt(info.Value, 10) ? '闭合' : '断开';
          break;
        default:
          break;
      }
    },
    getValueByNo(info) {
      return parseFloat(info.Value).toFixed(2) + info.dw;
    },
    getPhase(id) {
      switch (id % 3) {
        case 1:
          return 'A';
        case 2:
          return 'B';
        case 0:
          return 'C';
        default:
          return '';
      }
    },
    getRandomCCharacter() {
      const word = `%u${this.getRandomIntStr(19968, 40869)}`;
      return unescape(word);
    },
    getrandomInt(minNo, maxNo) {
      return parseInt(Math.round(Math.random() * (maxNo - minNo) + minNo), 10);
    },
    getRandomFloat(minNo, maxNo) {
      return (Math.random() * (maxNo - minNo) + minNo).toFixed(2);
    },
    getRandomIntStr(minNo, maxNo) {
      return this.getrandomInt(minNo, maxNo).toString(16);
    },
    getRandomMeterNo() {
      setTimeout(
        () => {
          this.meterNo = this.getRandomNo(100, 100000, 6);
          this.getRandomMeterNo();
        },
        2000,
      );
    },
    getFixLengthNo(noStr, length) {
      const len = noStr.length;
      if (noStr.length < length) {
        for (let i = 0; i < length - len; i += 1) {
          // eslint-disable-next-line no-param-reassign
          noStr = `0${noStr}`;
        }
      }
      return noStr;
    },
    getRandomNo(minNo, maxNo, length) {
      let noStr = parseInt(Math.random() * (maxNo - minNo) + minNo, 10).toString();
      const len = noStr.length;
      if (noStr.length < length) {
        for (let i = 0; i < length - len; i += 1) {
          noStr = `0${noStr}`;
        }
      }
      return noStr;
    },
  },
};
</script>
<style scoped>
.meter-order {
  color: #00706b;
}
.meter-container {
  color: red;
  margin: 8px 0;
}
.meter-stopping {
  color: green;
}
.meter-noUsing {
  color: gray;
}
.meter-no-container {
  min-height: 120px;
  background-image: url(../../assets/images/project/initiative-repair/meter.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.meter-no {
  font-size: 28px;
  letter-spacing: 11px;
  margin-top: 24px;
  margin-left: 10px;
}
.static-info {
  padding: 8px 16px;
  margin-left: -140px;
}
.meter-info {
  min-width: 136.05px;
  text-align: left;
  padding-left: 24px;
}
</style>
