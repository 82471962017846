<template>
  <div class="assign-order-dia d-flex flex-grow-1">
    <div class="col-6 d-flex flex-column align-items-sketch">
      <div class="none-height flex-grow-1 d-flex flex-column">
        <SectionFrame class="mb-1">
          <div class="dia-title">运维队伍</div>
        </SectionFrame>
        <SectionFrame class="flex-grow-1 px-4 py-2">
          <repair-team :isDetail="0"
          :teamInfo="selectTeam"
          v-on:togglePerson="changeSelect"></repair-team>
        </SectionFrame>
      </div>
      <div class="none-height flex-grow-1 d-flex flex-column mt-3">
        <SectionFrame class="mb-1">
          <div class="dia-title">运维要求</div>
        </SectionFrame>
        <SectionFrame class="flex-grow-1 d-flex flex-column">
          <textarea
            class="form-control flex-grow-1 bg-transparent text-white"
            v-model.trim="content"
          ></textarea>
        </SectionFrame>
      </div>
      <el-button size="mini" v-on:click="cancelAssign">取消</el-button>
    </div>
    <div class="col-6 d-flex flex-column">
      <div class="none-height flex-grow-1 d-flex flex-column">
        <SectionFrame class="mb-1">
          <div class="custom-control custom-switch custom-switch-large">
            <input
              type="checkbox"
              class="custom-control-input"
              id="send-work-msg"
              v-model="isSendWorkMsg"
            />
            <label class="custom-control-label dia-title" for="send-work-msg">维修短信</label>
          </div>
        </SectionFrame>
        <SectionFrame class="flex-grow-1 d-flex flex-column">
          <div
            class="p-2 bg-transparent text-white text-left"
            :class="{'text-secondary':!isSendWorkMsg}"
          >
            <span class="iconfont icon-dengpao mr-1"></span>
            {{work_message}}
          </div>
        </SectionFrame>
      </div>
      <div class="none-height flex-grow-1 d-flex flex-column mt-3">
        <SectionFrame class="mb-1">
          <div class="custom-control custom-switch custom-switch-large">
            <input
              type="checkbox"
              class="custom-control-input"
              id="send-user-msg"
              v-model="isSendUserMsg"
            />
            <label class="custom-control-label dia-title" for="send-user-msg">用户短信</label>
          </div>
        </SectionFrame>
        <SectionFrame class="flex-grow-1 d-flex flex-column">
          <div
            class="p-2 bg-transparent text-white text-left"
            :class="{'text-secondary':!isSendUserMsg}"
          >
            <span class="iconfont icon-dengpao mr-1"></span>
            {{usr_message}}
          </div>
        </SectionFrame>
      </div>
      <el-button  size="mini" type="primary" v-on:click="assignRepair">提交</el-button>
    </div>
  </div>
</template>
<script>
// 分派工单dialog
// import { events } from '@/assets/scripts/events';
import SectionFrame from '@/components/utils/section-frame.vue';
import repairTeam from '@/components/project-initiative-repair/repair-team.vue';
import { getAssignOrder } from '@/api/project/initiative-repair';

export default {
  name: 'assign-order-dia',
  components: {
    SectionFrame,
    repairTeam,
  },
  props: {
    // 预警Id
    eId: {
      type: String,
      default: '',
    },
    // 运维队伍message
    work_message: {
      type: String,
      default: '',
    },
    // user message
    usr_message: {
      type: String,
      default: '',
    },
    // 所选队伍
    selectTeam: {
      type: Object,
      default() {
        return {
          DuiWu: '开发分中心',
          RenYuanList: [],
          TeamIDstr: 'MjhCOTY0Qjc5Q0EyMjA1Ng',
        };
      },
    },
  },
  // 数据data
  data() {
    return {
      seletedPersenTeam: this.selectTeam,
      content: '请处理并及时反馈处理意见',
      isSendUserMsg: 0,
      isSendWorkMsg: 0,
    };
  },
  // 计算量
  computed: {
    accountId: {
      get() {
        return this.$store.getters.accountId;
      },
    },
  },
  watch: {
    // // 所选运维人员、队伍
    // seletedPersenTeam: {
    //   deep: true,
    //   handler() {},
    // },
    // 所选运维队伍
    selectTeam(newVal) {
      this.seletedPersenTeam = newVal;
    },
  },
  methods: {
    // 取消分派
    cancelAssign() {
      this.$emit('cancelAssign');
    },
    // 改变所选的人员
    changeSelect(isSelect, order) {
      this.$set(
        this.seletedPersenTeam.RenYuanList[order],
        'isSelect',
        isSelect,
      );
    },
    // 分派维修队伍
    assignRepair() {
      let ids = this.getSelectedIds();
      if (ids.length === 0) {
        return;
      }
      ids = ids.toString();
      getAssignOrder({
        UIDstr: this.accountId,
        EWIDstr: this.eId,
        TeamIDstr: this.seletedPersenTeam.TeamIDstr,
        UIDstrs: ids,
        Content: this.content,
        SendMessage_YW: this.isSendWorkMsg ? 1 : 0,
        SendMessage_KH: this.isSendUserMsg ? 1 : 0,
      }).then((res) => {
        this.$emit('assignedRepair', res.OrderIDstr);
      });
    },
    // 获取所选ids
    getSelectedIds() {
      const selectIds = [];
      this.seletedPersenTeam.RenYuanList.forEach((person) => {
        if (!Object.prototype.hasOwnProperty.call(person, 'isSelect')) {
          selectIds.push(person.UIDstr);
        } else if (person.isSelect) {
          selectIds.push(person.UIDstr);
        }
      });
      return selectIds;
    },
  },
};
</script>
<style scoped>
.assign-order-dia {
  font-size: 0.8rem;
}
.dia-title {
  line-height: 1.5rem;
}
.icon-dengpao {
  font-size: 1.2rem;
}
</style>
