//维修队伍
<template>
  <div class="repair-team">
    <div class="team-name d-flex justify-content-between">
      <div>{{teamInfo.DuiWu}}</div>
      <button
        v-if="stage<=4&&isDetail"
        v-on:click.stop="manageTeam"
        class="btn btn-sm btn-outline-primary"
      >{{stage==0?"分派":"撤回"}}</button>
    </div>
    <div class="team-members d-flex justify-content-start flex-wrap">
      <person-container
        :isDetail="isDetail"
        v-on:togglePerson="togglePerson(arguments,index)"
        :selectable="isDetail==0"
        v-for="(person,index) in teamInfo.RenYuanList"
        :key="'person-'+index"
        :personInfo="person"
      ></person-container>
    </div>
  </div>
</template>
<script>
import personContainer from '@/components/project-initiative-repair/person-container.vue';

export default {
  name: 'repair-team',
  components: {
    personContainer,
  },
  props: {
    stage: {
      type: Number,
      default: 0,
    },
    isDetail: {
      type: Number,
      default: 1,
    },
    teamInfo: {
      default() {
        return {
          DuiWu: '开发分中心',
          RenYuanList: [
            {
              Name: '测试',
              UIDstr: 'OTVCREZENjRCMkYzRDMxMQ',
              Phone: '232321',
            },
            {
              Name: '屠玉录',
              UIDstr: 'NDMzOEQ4QUJENDVEMDNGNQ',
              Phone: '15905347338',
            },
            {
              Name: '曾祥东',
              UIDstr: 'Qjk1Nzc2MURCMEJGODhCMA',
              Phone: '15853458228',
            },
          ],
          TeamIDstr: 'MjhCOTY0Qjc5Q0EyMjA1Ng',
        };
      },
      type: Object,
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    togglePerson(args, order) {
      this.$emit('togglePerson', args[0], order);
    },
    manageTeam() {
      console.log('this.dispatchRepair', this.stage);
      if (this.stage === 0) {
        this.$emit('dispatchRepair', this.teamInfo);
      } else {
        this.$emit('withdrawOrder');
      }
    },
  },
};
</script>
<style scoped>
.btn-sm {
  font-size: 0.6rem;
}
</style>
