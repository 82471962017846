//工单步骤
<template>
  <div
    class="
      work-steps
      d-flex
      justify-content-center
      align-items-center
      pb-2
      px-4
    "
  >
    <template v-for="(workStep, index) in stepsInfo">
      <div
        :key="'line-' + index"
        v-if="index > 0"
        class="d-flex flex-column"
        style="margin-top: -24px"
      >
        <div
          class="step-duration text-warning"
          v-html="
            workStep.TimeSpan ? `${parseInt(workStep.TimeSpan)}min` : '&nbsp;'
          "
        ></div>
        <div class="step-line"></div>
      </div>
      <work-step
        :key="'step-' + index"
        :isActive="
          (Number(activeStep) == index && index != 5) ||
          (index == stepsInfo.length - 1 && activeStep == 5) ||
          (index == 3 && activeStep == 6)
        "
        :stepInfo="workStep"
      ></work-step>
    </template>
  </div>
</template>
<script>
import workStep from '@/components/project-initiative-repair/work-step.vue';

export default {
  name: 'work-steps',
  components: {
    workStep,
  },
  props: {
    stepsInfo: {
      type: Array,
      default() {
        return [
          {
            Text: '新预警',
            Time: '2019-03-04 17:34:22',
            TimeSpan: '',
          },
          {
            Text: '工单派发',
            Time: '2019-03-11 15:58:01',
            TimeSpan: '9983.65',
          },
          {
            Text: '到达现场',
            Time: '',
            TimeSpan: '',
          },
          {
            Text: '反馈',
            Time: null,
            TimeSpan: null,
          },
          {
            Text: '工单审核',
            Time: '',
            TimeSpan: '',
          },
          {
            Text: '归档',
            Time: '',
            TimeSpan: '',
          },
        ];
      },
    },
    activeStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.step-line {
  width: 100px;
  height: 5px;
  background: #5f71a7;
}
.step-duration {
  font-size: 0.6rem;
  color:#8894BB;
}
</style>
